// Add you general and shared styles here
@font-face {
    font-family: 'LotaGrotesqueAlt3';
    src: url("./font/LosRegular.otf") format("opentype");
}

@font-face {
    font-family: 'LotaGrotesqueAlt3';
    font-weight: bold;
    src: url("./font/LosBold.otf") format("opentype");
}

@font-face {
    font-family: 'LotaGrotesqueAlt3';
    font-weight: 900;
    font-style: normal;
    src: url("./font/LosBlack.otf") format("opentype");
}

@font-face {
    font-family: 'LotaGrotesqueAlt3';
    font-weight: 600;
    font-style: normal;
    src: url("./font/LosSemiBold.otf") format("opentype");
}

@font-face {
    font-family: 'Circular Std';
    src: url('./font/CircularStd-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./font/CircularStd-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./font/CircularStd-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('./font/CircularStd-BookItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./font/CircularStd-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./font/CircularStd-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

html{
    height: 100%
}
body{
    height: 100%;
    margin: 0;
    background-color: #f6f7fb;
    font-family: 'LotaGrotesqueAlt3';
}
html, h1, h2, h3, h4, h5, h6, p, span, div {    
    font-family: 'LotaGrotesqueAlt3';
}

#root{
    height: 100%
}
.ltr,.rtl{
    height: 100%
}